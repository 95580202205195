import _ from 'lodash';
import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';

import Table from 'components/Table';
import RadioColumnFilter from 'components/Table/filters/RadioColumnFilter';

import useConfirm from 'hooks/useConfirm';
import useUserTableSettings from 'hooks/useUserTableSettings';
import useDeleteReservation from 'hooks/services/reservation/useDeleteReservation';

import useUser from 'hooks/useUser';
import config from 'config';
import getReservationStatus from 'utils/reservations';
import CreateReservationModal from '../CreateReservationModal';
import EditReservationModal from '../EditReservationModal';

const MyReservationsTable = ({ probes, reservations }: any) => {
  const userTableSettings = useUserTableSettings('reservations');

  const { isAdmin, uidNumber: userId } = useUser();
  const [newReservationModal, setNewReservationModal] = useState<any>({
    show: false,
    from: undefined,
    until: undefined,
  });

  const [editReservationModal, setEditReservationModal] = useState<any>({
    show: false,
    reservation: undefined,
  });

  const [deleteReservation, { isLoading: isLoadingDeleteReservation }] = useDeleteReservation();

  // todo remove or refactor to avoid eslint error
  if (isLoadingDeleteReservation) {
    console.log('loading delete reservation');
  }
  const confirm = useConfirm();

  const handleDeleteReservation = (reservation: any) => {
    confirm({
      body: `Delete reservation for ${reservation.location} (${reservation.device}) from ${reservation.from} to ${reservation.until}?`,
      onOk: () => deleteReservation({ reservationId: reservation.id }),
      title: 'Delete reservation',
    });
  };

  const handleEditReservationSuccess = () => {
    setEditReservationModal({
      ...editReservationModal,
      show: false,
    });
  };

  const handleNewReservationSuccess = () => {
    setNewReservationModal({
      ...newReservationModal,
      show: false,
    });
  };

  const tableColumns = useMemo(
    () => [
      // {
      //   accessor: 'id',
      //   disableFilters: true,
      //   Header: 'ID',
      // },
      {
        accessor: 'location',
        Header: 'Location',
        disableFilters: true,
      },
      {
        accessor: 'alias',
        Header: 'Name',
        disableFilters: true,
      },
      {
        accessor: 'device',
        Header: 'Device',
        disableFilters: true,
      },
      // {
      //   accessor: 'probe',
      //   Header: 'Probe',
      //   disableFilters: true,
      // },
      {
        accessor: 'status',
        Filter: RadioColumnFilter,
        Header: 'Reservation Status',
        disableFilters: true,
      },
      {
        accessor: 'from',
        Header: 'From',
        disableFilters: true,
      },
      {
        accessor: 'until',
        Header: 'Until',
        disableFilters: true,
      },
      // now empty, so mabye for future...
      // {
      //   accessor: 'note',
      //   Header: 'Note',
      // },

    ],
    [],
  );

  const [tableData, setTableData] = useState<any[] | null>(null);

  useEffect(
    () => {
      if (reservations && probes) {
        setTableData(reservations ? reservations
          .filter((reservation: any) => String(reservation.user_id) === String(userId) || isAdmin)
          .map((reservation: any) => {
            const probe = probes.find(
              (val: any) => val.probe_name === reservation.probe_name
            );
            return ({
              ...reservation,
              from: moment(reservation.from).format(config.ui.dateTimeFormat),
              until: moment(reservation.until).format(config.ui.dateTimeFormat),
              probe: probe?.probe_name || 'unknown',
              alias: probe?.probe_alias || 'unknown',
              status: getReservationStatus(
                moment(reservation.from).format(config.ui.dateTimeFormat),
                moment(reservation.until).format(config.ui.dateTimeFormat),
                config.ui.dateTimeFormat
              ),
              location: probe?.location,
              device: probe?.device,
            });
          }) : null);
      }
    },
    [probes, reservations],
  );

  const expirationCheck = () => {
    setTableData((prevState) => (prevState
      ? prevState.map((item) => {
        const status = getReservationStatus(item.from, item.until, config.ui.dateTimeFormat);
        return ({
          ...item,
          status,
        });
      })
      : null));
  };

  const expirationCheckPeriod = 1;
  useEffect(() => {
    const timerId = setInterval(() => expirationCheck(), expirationCheckPeriod * 1000);
    return () => clearInterval(timerId);
  }, []);

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        render: (reservation: any) => {
          const { from, until } = reservation;
          return (
            <button
              className={clsx('text-button', { 'bu-ml-1': false })}
              key="edit"
              disabled={getReservationStatus(from, until, config.ui.dateTimeFormat) === 'expired'}
              onClick={() => setEditReservationModal({
                show: true,
                reservation: {
                  ...reservation,
                  from: moment(reservation.from, config.ui.dateTimeFormat),
                  until: moment(reservation.until, config.ui.dateTimeFormat),
                },
              })}
              type="button"
            >
              Edit
            </button>
          );
        }
      },
      {
        render: (reservation: any) => {
          const { from, until } = reservation;
          return (
            <button
              className={clsx('text-button', { 'bu-ml-1': true, 'text-danger': 'danger' })}
              key="delete"
              disabled={getReservationStatus(from, until, config.ui.dateTimeFormat) === 'expired'}
              onClick={() => handleDeleteReservation(reservation)}
              type="button"
            >
              Delete
            </button>
          );
        }
      },
    ],
  }), [handleDeleteReservation]);

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        columns={tableColumns}
        data={tableData || []}
        initialState={userTableSettings?.initialState}
        NewButtonProps={{
          content: 'New Reservation',
          onClick: () => setNewReservationModal({ show: true, from: undefined, until: undefined }),
        }}
        onStateChange={userTableSettings.updateTableSettings}
        rowActions={tableRowActions}
        skipReset={false}
      />
      )}
      {newReservationModal?.show && (
        <CreateReservationModal
          onHide={() => setNewReservationModal({
            ...newReservationModal,
            show: false,
          })}
          onSuccess={handleNewReservationSuccess}
          probes={probes}
          reservations={reservations}
          selectedProbes={[]}
          {...newReservationModal}
        />
      )}
      {editReservationModal?.show && (
        <EditReservationModal
          onHide={() => setEditReservationModal({
            reservation: undefined,
            show: false,
          })}
          onSuccess={handleEditReservationSuccess}
          probe={_.find(probes, ['probe_name', editReservationModal?.reservation?.probe_name])}
          selectedReservation={editReservationModal?.reservation}
          show={editReservationModal?.show}
        />
      )}
    </>
  );
};

export default MyReservationsTable;
