/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
// import { TagsInput } from 'react-tag-input-component';
import {
  Alert,
  Button, Col, Form, FormControl,
  Modal,
} from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import AutosuggestTagInput from 'components/AutosuggestTagInput/AutosuggestTagInput';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import { Sim } from 'hooks/services/simManagement/interfaces';
import useEditSubscriber from '../../../../../hooks/services/simManagement/subscriber/useEditSubscriber';
import useAlerts from '../../../../../hooks/useAlerts';
import useForm from '../../../../../hooks/useForm';

export interface IEditSimModalProps {
  show: boolean;
  onHide: () => void;
  simData: any;
  getSimCards: any;
}

type FormErrors = {
  position?: string;
  iccid?: string;
  msisdn?: string;
  imsi?: string;
  tariff?: string;
  wnw?: string;
  op_wnw?: string;
  psp?: string;
  wholesale_id?: string;
  itg_id?: string;
  secret?: string;
  name?: string;
  origin?: string;
  prepaid?: string;
  sim_type?: string;
  type?: string;
  modified?: string;
  network?: string;
};

const EditSimModal = (props: IEditSimModalProps) => {
  const {
    show, onHide, simData, getSimCards,
  } = props;

  const { data: subscribers } = useGetSubscribers();
  const [showError, setShowError] = useState(true);
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const [updateSims, { error }] = useEditSubscriber({
    onSuccess: () => {
      setMessage(`sim ${simData.iccid} has been updated!`);
      getSimCards();
      onHide();
    },
    onError: () => setErrMessage(`Failed to update sim ${simData.iccid}`),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, setValue, submitted, values: formValues,
  } = useForm({
    initialValues: {
      iccid: simData.iccid,
      msisdn: simData.msisdn || '',
      imsi: simData.imsi || '',
      tariff: simData.tariff || '',
      wnw: simData.wnw || '',
      op_wnw: simData.op_wnw || '',
      psp: simData.psp || '',
      wholesale_id: simData.wholesale_id || '',
      itg_id: simData.itg_id || '',
      secret: simData.secret || '',
      name: simData.name || '',
      origin: simData.origin || '',
      prepaid: simData.prepaid?.toString() || '',
      type: simData.type || '',
      sim_type: simData.sim_type,
      position: simData.position || '',
      modified: simData.modified || '',
      lab: simData.lab || false,
      live: simData.live || false,
      tags: simData.tags || [],
    },
    onSubmit: () => {
      const date = new Date();
      const timeMod = moment(date).format('YYYY-MM-DD HH:mm:ss');
      updateSims({
        id: simData.id,
        sim: {
          iccid: formValues.iccid,
          msisdn: (formValues.msisdn).replace('+', ''),
          imsi: formValues.imsi,
          tariff: formValues.tariff,
          wnw: formValues.wnw,
          op_wnw: formValues.op_wnw,
          psp: formValues.psp,
          wholesale_id: formValues.wholesale_id,
          itg_id: formValues.itg_id,
          secret: formValues.secret,
          name: formValues.name,
          origin: formValues.origin,
          prepaid: formValues.prepaid,
          type: formValues.type,
          sim_type: formValues.sim_type,
          position: formValues.position,
          modified: timeMod,
          lab: formValues.lab,
          live: formValues.live,
          tags: formValues.tags,
        },
      });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (values.type === 'mappable' || values.type === 'static') {
        if (!values?.iccid || values?.iccid === '') {
          errors.iccid = 'Please fill in the ICCID.';
        }
        if (!values?.msisdn || values?.msisdn === '') {
          errors.msisdn = 'Please fill in the MSISDN.';
        }
        if (!(/^\+?\d{1,15}$/).test(values?.msisdn)) {
          errors.msisdn = 'Please fill in the MSISDN in correct format (e.g. 499876543211).';
        }
        if (!values?.imsi || values?.imsi === '') {
          errors.imsi = 'Please fill in the IMSI.';
        }
        if (!(/^\d{1,15}$/).test(values?.imsi)) {
          errors.imsi = 'Please fill in the IMSI in correct format';
        }
        if (!values?.sim_type || values?.imsi === '') {
          errors.sim_type = 'Please select SIM Type.';
        }
      }
      if (values.type === 'fixed_line' || values.type === 'sip') {
        if (values?.iccid) {
          errors.iccid = 'Fixed subscriber can not have ICCID.';
        }
        if (values?.imsi || values?.imsi !== '') {
          errors.imsi = 'Fixed subscriber can not have IMSI.';
        }
        if (values?.sim_type) {
          errors.sim_type = 'Fixed subscriber can not have SIM Type.';
        }
        if (values?.position) {
          errors.position = 'Fixed subscriber can not have Position.';
        }
      }
      if (!values?.prepaid || values?.prepaid === '') {
        errors.prepaid = 'Please fill in the prepaid info.';
      }
      if (!values?.type || values?.type === '') {
        errors.type = 'Please fill in the type.';
      }
      if (!(/^\d{0,15}$/).test(values?.secret)) {
        errors.secret = 'Please fill in the PIN in correct format';
      }
      if (!values?.lab && !values?.live) {
        errors.network = 'Please select at least one network type';
      }
      /*
      if (!values?.tariff || values?.tariff === '') {
        errors.tariff = 'Please fill in the tariff.';
      }
      if (!values?.wnw || values?.wnw === '') {
        errors.wnw = 'Please fill in the wnw.';
      }
      if (!values?.op_wnw || values?.op_wnw === '') {
        errors.op_wnw = 'Please fill in the op_wnw.';
      }
      if (!values?.psp || values?.psp === '') {
        errors.psp = 'Please fill in the psp.';
      }
      if (!values?.wholesale_id || values?.wholesale_id === '') {
        errors.wholesale_id = 'Please fill in the wholesale ID.';
      }
      if (!values?.itg_id || values?.itg_id === '') {
        errors.itg_id = 'Please fill in the ITG ID.';
      }
      if (!values?.pin || values?.pin === '') {
        errors.pin = 'Please fill in the PIN.';
      }
      if (!values?.name || values?.name === '') {
        errors.name = 'Please fill in the name.';
      }
      if (!values?.origin || values?.origin === '') {
        errors.origin = 'Please fill in the origin.';
      }
      if (!values?.modified || values?.modified === '') {
        errors.modified = 'Please fill in the modified info.';
      }
 */
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      id="sim-edit-modal"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Edit Sim ${simData.iccid}` }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form onSubmit={handleSubmit}>
            {error?.message && showError && (
              <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                {error?.message}
              </Alert>
            )}
            <Form.Row className="justify-content-between">
              <Form.Group key="iccid">
                <Form.Label htmlFor="iccid">ICCID</Form.Label>
                <FormControl
                  id="iccid"
                  isInvalid={submitted && Boolean(formErrors?.iccid)}
                  name="iccid"
                  onChange={handleChange('iccid')}
                  disabled={(formValues.type === 'mappable' || formValues.type === 'static')}
                  type="text"
                  defaultValue={formValues?.iccid}
                />
                {submitted && Boolean(formErrors?.iccid) && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.iccid}
                  </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="msisdn" className="ml-2">
                <Form.Label htmlFor="name">MSISDN</Form.Label>
                <FormControl
                  autoFocus
                  id="msisdn"
                  isInvalid={submitted && Boolean(formErrors?.msisdn)}
                  name="msisdn"
                  onChange={handleChange('msisdn')}
                  required
                  type="text"
                  value={formValues?.msisdn}
                />
                {submitted && Boolean(formErrors?.msisdn) && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.msisdn}
                  </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-between">
              <Form.Group key="imsi">
                <Form.Label htmlFor="imsi">IMSI</Form.Label>
                <FormControl
                  id="imsi"
                  isInvalid={submitted && Boolean(formErrors?.imsi)}
                  name="imsi"
                  onChange={handleChange('imsi')}
                  type="text"
                  value={formValues?.imsi}
                />
                {submitted && formErrors?.imsi && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.imsi}
                  </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="tariff" className="ml-2">
                <Form.Label htmlFor="tariff">Tariff</Form.Label>
                <FormControl
                  id="tariff"
                  isInvalid={submitted && Boolean(formErrors?.tariff)}
                  name="tariff"
                  onChange={handleChange('tariff')}
                  type="text"
                  value={formValues?.tariff}
                />
                {submitted && formErrors?.tariff && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.tariff}
                  </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-between">
              <Form.Group key="wnw">
                <Form.Label htmlFor="wnw">WNW</Form.Label>
                <FormControl
                  id="wnw"
                  isInvalid={submitted && Boolean(formErrors?.wnw)}
                  name="wnw"
                  onChange={handleChange('wnw')}
                  type="text"
                  value={formValues?.wnw}
                />
                {submitted && formErrors?.wnw && (
                <FormControl.Feedback type="invalid">
                  {formErrors?.wnw}
                </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="op_wnw" className="ml-2">
                <Form.Label htmlFor="op_wnw">OP WNW</Form.Label>
                <FormControl
                  id="op_wnw"
                  isInvalid={submitted && Boolean(formErrors?.op_wnw)}
                  name="op_wnw"
                  onChange={handleChange('op_wnw')}
                  type="text"
                  value={formValues?.op_wnw}
                />
                {submitted && formErrors?.op_wnw && (
                <FormControl.Feedback type="invalid">
                  {formErrors?.op_wnw}
                </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-between">
              <Form.Group key="psp">
                <Form.Label htmlFor="psp">PSP</Form.Label>
                <FormControl
                  id="psp"
                  isInvalid={submitted && Boolean(formErrors?.psp)}
                  name="psp"
                  onChange={handleChange('psp')}
                  type="text"
                  value={formValues?.psp}
                />
                {submitted && formErrors?.psp && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.psp}
                  </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="wholesale_id" className="ml-2">
                <Form.Label htmlFor="wholesale_id">Wholesale ID</Form.Label>
                <FormControl
                  id="wholesale_id"
                  isInvalid={submitted && Boolean(formErrors?.wholesale_id)}
                  name="wholesale_id"
                  onChange={handleChange('wholesale_id')}
                  type="text"
                  value={formValues?.wholesale_id}
                />
                {submitted && formErrors?.wholesale_id && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.wholesale_id}
                  </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-between">
              <Form.Group key="itg_id">
                <Form.Label htmlFor="itg_id">ITG ID</Form.Label>
                <FormControl
                  id="itg_id"
                  isInvalid={submitted && Boolean(formErrors?.itg_id)}
                  name="itg_id"
                  onChange={handleChange('itg_id')}
                  type="text"
                  value={formValues?.itg_id}
                />
                {submitted && formErrors?.itg_id && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.itg_id}
                  </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="secret" className="ml-2">
                <Form.Label htmlFor="secret">PIN</Form.Label>
                <FormControl
                  id="secret"
                  isInvalid={submitted && Boolean(formErrors?.secret)}
                  name="secret"
                  onChange={handleChange('secret')}
                  type="text"
                  value={formValues?.secret}
                />
                {submitted && formErrors?.secret && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.secret}
                  </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-between">
              <Form.Group key="name">
                <Form.Label htmlFor="name">Name</Form.Label>
                <FormControl
                  autoFocus
                  id="name"
                  isInvalid={submitted && Boolean(formErrors?.name)}
                  name="name"
                  onChange={handleChange('name')}
                  type="text"
                  value={formValues?.name}
                />
                {submitted && Boolean(formErrors?.name) && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.name}
                  </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="origin" className="ml-2">
                <Form.Label htmlFor="origin">Origin</Form.Label>
                <FormControl
                  autoFocus
                  id="origin"
                  isInvalid={submitted && Boolean(formErrors?.origin)}
                  name="origin"
                  onChange={handleChange('origin')}
                  type="text"
                  value={formValues?.origin}
                />
                {submitted && Boolean(formErrors?.origin) && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.origin}
                  </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Label htmlFor="network">Network</Form.Label>
            <Form.Row>
              <Form.Group key="live" className="ml-2">
                <Form.Check
                  checked={formValues?.live}
                  value="live"
                  id="live"
                  key="live"
                  type="checkbox"
                  label="Live"
                  inline
                  aria-label="item"
                  className="lg mr-0 ml-3"
                  onChange={(() => {
                    if (formValues?.live === true) {
                      setValue('lab', true);
                    }
                    setValue('live', !formValues?.live);
                  })}
                />
              </Form.Group>
              <Form.Group key="lab" className="ml-2">
                <Form.Check
                  checked={formValues?.lab}
                  value="lab"
                  id="lab"
                  key="lab"
                  type="checkbox"
                  label="Lab"
                  inline
                  aria-label="item"
                  className="lg mr-0 ml-3"
                  onChange={(() => {
                    if (formValues?.lab === true) {
                      setValue('live', true);
                    }
                    setValue('lab', !formValues?.lab);
                  })}
                />
                {submitted && Boolean(formErrors?.network) && (
                <div className="invalid-feedback d-block">{formErrors?.network}</div>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group style={{ width: '100%' }}>
                <Form.Label>Tags</Form.Label>
                <AutosuggestTagInput
                  tags={formValues?.tags}
                  separators={['Enter', ' ', ',']}
                  onChangeInput={(newTags: any) => setValue('tags', newTags)}
                  suggestions={_.uniq(_.union(...(subscribers || [])
                    .filter((sub:Sim) => sub.position)
                    .map((sub:Sim) => sub?.tags)))}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={3} key="prepaid">
                <Form.Label htmlFor="prepaid">Prepaid</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={formValues.prepaid}
                  id="prepaid"
                  name="prepaid"
                  required
                  onChange={handleChange('prepaid')}
                >
                  <option value="" disabled hidden>prepaid</option>
                  <option value="true">yes</option>
                  <option value="false">no</option>
                </Form.Control>
              </Form.Group>
              <Form.Group key="type" className="ml-2">
                <Form.Label htmlFor="type">Type</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={formValues.type}
                  id="type"
                  name="type"
                  required
                  onChange={handleChange('type')}
                >
                  <option value="" disabled hidden>type</option>
                  <option value="mappable">Mappable</option>
                  <option value="static">Static</option>
                  <option value="fixed_line">Fixed Line</option>
                  <option value="sip">SIP</option>
                </Form.Control>
              </Form.Group>
              <Form.Group key="sim_type" as={Col} md={3}>
                <Form.Label htmlFor="sim_type">SIM Type</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={formValues.sim_type}
                  id="sim_type"
                  name="sim_type"
                  onChange={handleChange('sim_type')}
                  isInvalid={submitted && Boolean(formErrors?.sim_type)}
                >
                  <option value="">No SIM Type</option>
                  <option value="single">Single</option>
                  <option value="master">Master</option>
                  <option value="slave">Slave</option>
                </Form.Control>
                {submitted && Boolean(formErrors?.sim_type) && (
                <FormControl.Feedback type="invalid">
                  {formErrors?.sim_type}
                </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={3} key="position">
                <Form.Label htmlFor="position">Position</Form.Label>
                <FormControl
                  autoFocus
                  id="position"
                  isInvalid={submitted && Boolean(formErrors?.position)}
                  name="position"
                  disabled
                  type="text"
                  defaultValue={formValues?.position}
                />
                {submitted && Boolean(formErrors?.position) && (
                <FormControl.Feedback type="invalid">
                  {formErrors?.position}
                </FormControl.Feedback>
                )}
              </Form.Group>
              <Form.Group key="modified" className="ml-2">
                <Form.Label htmlFor="modified">Modified</Form.Label>
                <FormControl
                  autoFocus
                  id="modified"
                  isInvalid={submitted && Boolean(formErrors?.modified)}
                  name="modified"
                  disabled
                  onChange={handleChange('modified')}
                  type="text"
                  value={formValues?.modified}
                />
                {submitted && Boolean(formErrors?.modified) && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.modified}
                  </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <div className="text-right">
              <Button className="btn btn-secondary" style={{ color: 'black' }} onClick={onHide}>Close</Button>
              <Button
                className="btn btn-primary"
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditSimModal;
