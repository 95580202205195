import _ from 'lodash';
import { queryCache, useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type ReservationData = {
  from?: string;
  until?: string;
};

type CreateReservatonRequestData = {
  reservationId: number;
  reservation: ReservationData
};

export default function usePatchReservation(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ reservationId, reservation }: CreateReservatonRequestData) => axios.patch(
    `/reservations/api/reservation/${reservationId}`,
    reservation,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
      queryCache.invalidateQueries('reservations');
    },
  });
}
