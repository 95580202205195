import _ from 'lodash';
import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';

import moment from 'moment';

import {
  Col, Row,
} from 'react-bootstrap';

import RadioColumnFilter from 'components/Table/filters/RadioColumnFilter';
import TimelineWithTable from 'components/TimelineWithTable';

import useDeleteReservation from 'hooks/services/reservation/useDeleteReservation';
import useConfirm from 'hooks/useConfirm';
import useAlerts from 'hooks/useAlerts';

import useUser from 'hooks/useUser';
import useGetUsers from 'hooks/services/user/useGetUsers';
import CreateReservationModal from '../CreateReservationModal';
import EditReservationModal from '../EditReservationModal';

const canMoveItem = (from: any, until: any) => {
  const momentUntil = moment(until);
  const momentNow = moment();

  return momentUntil.isAfter(momentNow);
};

const canResizeItem = (from: any, until: any) => {
  const momentFrom = moment(from);
  const momentUntil = moment(until);
  const momentNow = moment();

  if (momentFrom.isAfter(momentNow) && momentUntil.isAfter(momentNow)) {
    return 'both';
  } if (momentUntil.isAfter(momentNow)) {
    return 'right';
  }
  return false;
};

const ReservationsTimeline = ({ probes, reservations }: any) => {
  const [selectedProbes, setSelectedProbes] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const { data: users } = useGetUsers();
  const { isAdmin, uidNumber: currentUserId } = useUser();

  const timeGap = 15 * 60 * 1000;
  const minResTime = 15 * 60 * 1000;
  const defaultResTime = 2 * 60 * 60 * 1000;

  // todo: mouse wheel scrolling to zoom in/out time line behaves wierd
  // compared to magnifier buttons

  const [newReservationModal, setNewReservationModal] = useState<any>({
    show: false,
    from: undefined,
    until: undefined,
  });

  const [editReservationModal, setEditReservationModal] = useState<any>({
    show: false,
    reservation: undefined,
  });

  const [deleteReservation] = useDeleteReservation();

  const confirm = useConfirm();
  const { showAlert } = useAlerts();

  const handleDeleteReservation = (reservation: any) => {
    const {
      id: reservationId,
      from,
      until,
      probe_name: probeName,
      note,
    } = reservation;
    if (moment(until).valueOf() < new Date().getTime()) {
      showAlert({
        id: 'past-reservation-message',
        content: 'You cannot delete a reservation from the past.',
        variant: 'danger',
      });
    } else {
      confirm({
        body: (
          <>
            <p className="mb-2">
              {`Probe: ${probeName}`}
              <br />
              <br />
              {`From: ${moment(from).format('DD/MM/YYYY HH:mm')}`}
              <br />
              {`Until: ${moment(until).format('DD/MM/YYYY HH:mm')}`}
            </p>
            <p className="mb-3">{note}</p>
            <b className="mb-1">Delete reservation?</b>
          </>),
        onOk: () => deleteReservation({ reservationId }),
        title: 'Delete reservation',
      });
    }
  };

  const handleEditReservationSuccess = () => {
    setEditReservationModal({
      ...editReservationModal,
      show: false,
    });
  };

  const handleNewReservationSuccess = () => {
    setSelectedProbes([]);
    setNewReservationModal({
      ...newReservationModal,
      show: false,
    });
  };

  const handleCanvasDoubleClick = (probeName: any, from: any) => {
    if (from < new Date().getTime()) {
      showAlert({
        id: 'past-reservation-message',
        content: 'You cannot make a reservation in the past.',
        variant: 'danger',
      });
    } else {
      setSelectedProbes([probeName]);
      setNewReservationModal({
        show: true,
        from,
        until: from + defaultResTime,
        single: true,
      });
    }
  };

  const handleItemResize = (id: any, time: any, edge: any) => {
    const reservation = _.find(reservations, ['id', id]);

    if (edge === 'left') {
      setEditReservationModal({
        show: true,
        reservation: {
          ...reservation,
          from: time,
          until: moment(reservation?.until).valueOf(),
        },
      });
    } else {
      setEditReservationModal({
        show: true,
        reservation: {
          ...reservation,
          from: moment(reservation?.from).valueOf(),
          until: time,
        },
      });
    }
  };

  const detectCollision = (
    start: number,
    end: number,
    itemId: number,
    groupId: number,
  ): { start: number, end: number }[] => {
    // detect all collisions
    const inCollisionItems: { start: number, end: number }[] = [];
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      if (groupId === item.group && itemId !== item.id) {
        const itemStart = moment(item.startDate).valueOf();
        const itemEnd = moment(item.endDate).valueOf();
        if ((start < itemEnd + timeGap && end > itemStart - timeGap)
          || (start > itemStart - timeGap && end < itemEnd + timeGap)) {
          inCollisionItems.push({ start: itemStart, end: itemEnd });
        }
      }
    }
    return inCollisionItems;
  };

  const handleItemMove = (id: any, from: any) => {
    const newFrom = from;

    const reservation = _.find(reservations, ['id', id]);
    const reservationFrom = moment(reservation?.from).valueOf();
    const reservationUntil = moment(reservation?.until).valueOf();

    const duration = reservationUntil - reservationFrom;
    const newUntil = moment(newFrom).add(duration).valueOf();

    setEditReservationModal({
      show: true,
      reservation: { ...reservation, from: newFrom, until: newUntil },
    });
  };

  // calculate how reservatio will look like after editing
  const getPreview = (
    action: string, resizeEdge: string, editedItem: any, proposedTime: number
  ): { start: number, end: number } => {
    // compute edited item start/end
    let editedStart = 0;
    let editedEnd = 0;
    if (action === 'resize') {
      if (resizeEdge === 'right') {
        editedStart = moment(editedItem.startDate).valueOf();
        editedEnd = proposedTime;
      }
      if (resizeEdge === 'left') {
        editedStart = proposedTime;
        editedEnd = moment(editedItem.endDate).valueOf();
      }
    } else if (action === 'move') {
      editedStart = proposedTime;
      editedEnd = proposedTime
        + moment(editedItem.endDate).valueOf()
        - moment(editedItem.startDate).valueOf();
    } else {
      console.log('moveResizeValidator: unknown action');
    }
    return ({ start: editedStart, end: editedEnd });
  };

  // catch events when is moved or resized into past and disable that
  // also enable only 15min steps
  // and detect overlap events
  const moveResizeValidator = (action: 'move' | 'resize', editedItem: any, time: number, resizeEdge: 'left' | 'right') => {
    const { id } = editedItem;
    const groupId = editedItem.group;

    // align to 15min grid
    let proposedTime = Math.ceil(time / timeGap) * (timeGap);

    // check minimal event duration and avoid start>end with minimal time gap
    if (resizeEdge === 'right' && proposedTime < (moment(editedItem.startDate).valueOf() + minResTime)) {
      proposedTime = moment(editedItem.startDate).valueOf() + minResTime;
    }
    // check minimal event duration and avoid end<start with minimal time gap
    if (resizeEdge === 'left' && proposedTime > (moment(editedItem.endDate).valueOf() - minResTime)) {
      proposedTime = moment(editedItem.endDate).valueOf() - minResTime;
    }

    // avoid past
    if (proposedTime < new Date().getTime() && (action === 'move' || (action === 'resize' && resizeEdge === 'right'))) {
      proposedTime = Math.ceil(new Date().getTime() / (timeGap)) * (timeGap);
    }

    // calculate preview of reservation
    let { start: editedStart, end: editedEnd } = getPreview(
      action, resizeEdge, editedItem, proposedTime,
    );

    // detect all collisions
    let inCollisionItems = detectCollision(editedStart, editedEnd, id, groupId);
    do { // repeat until all collision will be solved and free space found
      if (inCollisionItems.length > 0) {
        if (action === 'resize') {
          if (resizeEdge === 'right') {
            proposedTime = inCollisionItems.reduce((p, v) => (p.start < v.start ? p : v)).start
              - timeGap;
          }
          if (resizeEdge === 'left') {
            proposedTime = inCollisionItems.reduce((p, v) => (p.end > v.end ? p : v)).end
              + timeGap;
          }
        } else if (action === 'move') {
          proposedTime = inCollisionItems.reduce((p, v) => (p.end > v.end ? p : v)).end
            + timeGap;
        } else {
          console.log('moveResizeValidator-colision: unknown action');
        }

        // ensure new proposedTime is not in collision with any other reservation
        ({ start: editedStart, end: editedEnd } = getPreview(
          action, resizeEdge, editedItem, proposedTime,
        ));
        inCollisionItems = detectCollision(editedStart, editedEnd, id, groupId);
      }
    } while (inCollisionItems.length > 0);

    // return proposed time
    return proposedTime;
  };

  const handleSelectedRowsChange = useCallback((rows: any) => {
    setSelectedProbes((prevState: any) => {
      const newSelectedProbes = _.map(rows, 'probe_name');

      if (!_.isEqual(prevState, newSelectedProbes)) {
        return newSelectedProbes;
      }

      return prevState;
    });
  }, []);

  const columns = useMemo(() => [
    {
      accessor: 'location',
      Header: 'Location',
      disableFilters: true,
    },
    {
      accessor: 'device',
      Header: 'Device',
      disableFilters: true,
    },
    {
      accessor: 'status',
      Filter: RadioColumnFilter,
      Header: 'Status',
      disableFilters: true,
    }], []);

  const groups = probes ? probes.map((probe: any) => ({
    ...probe,
    id: probe.probe_name,
    title: probe.probe_name,
    location: `${probe?.location} - ${probe?.probe_alias || 'Unknown alias'}`,
    status: (probe?.status === 'online' && (probe?.devices && probe?.devices[0]?.status === 'online')) ? 'online' : 'offline',
  })) : [];

  const getTimelineItem = (
    { reservation, title, startDate, endDate, itemProps, showPopover, probe, from, until }: {
      reservation: any,
      title: any,
      startDate: any,
      endDate: any,
      itemProps: any,
      showPopover: any,
      probe: any,
      from: any,
      until: any
    }
  ) => ({
    id: reservation.id ? reservation.id : `index${reservation.index}`,
    group: reservation.probe_name,
    title,
    startDate,
    endDate,
    canMove: (String(reservation.user_id) === String(currentUserId) || isAdmin)
      ? canMoveItem(reservation.from, reservation.until) : false,
    canResize: (String(reservation.user_id) === String(currentUserId) || isAdmin)
      ? canResizeItem(reservation.from, reservation.until) : false,
    itemProps,
    popover: showPopover ? {
      title: probe?.probe_name,
      content: (
        <>
          <p className="mb-2">
            {`From: ${from.format('DD/MM/YYYY HH:mm')}`}
            <br />
            {`Until: ${until.format('DD/MM/YYYY HH:mm')}`}
            <br />
            {'By: '} <a href={`mailto:${users?.find((user:any) => user.uid === reservation.user_id)?.mail}`}>{users?.find((user:any) => user.uid === reservation.user_id)?.cn}</a>
          </p>
          <p className="mb-3">{reservation.note}</p>
          <Row>
            <Col>
              <button
                id="edit-reservation"
                className="text-button"
                type="button"
                aria-label="Edit Reservation"
                onClick={() => setEditReservationModal({ show: true, reservation })}
                disabled={moment(reservation.until).isBefore(moment())
                  || (!isAdmin && String(reservation.user_id) !== String(currentUserId))}
              >
                Edit
              </button>
            </Col>
            <Col xs="auto">
              <button
                className="text-button text-danger"
                onClick={() => handleDeleteReservation(reservation)}
                type="button"
                aria-label="Delete Reservation"
                disabled={moment(reservation.until).isBefore(moment())
                  || (!isAdmin && String(reservation.user_id) !== String(currentUserId))}
              >
                Delete
              </button>
            </Col>
          </Row>
        </>),
    } : undefined,
  });

  const getItemProps = (from: any, until: any, owned: boolean) => {
    const momentNow = moment();

    // upcoming
    if (owned && moment(from).isAfter(momentNow) && moment(until).isAfter(momentNow)) {
      return {
        style: {
          background: '#1E75CB',
        },
      };
    }
    // active
    if (owned && moment(until).isAfter(momentNow)) {
      return {
        style: {
          background: '#339933',
        },
      };
    }
    // expired
    return {
      style: {
        background: '#6b6b6b',
      },
    };
  };

  const expirationCheck = () => {
    setItems((prevState) => prevState.map((item) => {
      const itemProps = getItemProps(
        item.startDate,
        item.endDate,
        (String(item.user_id) === String(currentUserId) || isAdmin)
      );
      return ({
        ...item, itemProps,
      });
    }));
  };

  const expirationCheckPeriod = 1;
  useEffect(() => {
    const timerId = setInterval(() => expirationCheck(), expirationCheckPeriod * 1000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    // filter only reservations which has probes
    setItems(_.map(reservations, (reservation) => {
      const probe = _.find(probes, ['probe_name', reservation.probe_name]);

      const from = moment(reservation.from);
      const until = moment(reservation.until);

      const title = from.isSame(until, 'day')
        ? `${from.format('DD/MM/YYYY HH:mm')} - ${until.format('HH:mm')}`
        : `${from.format('DD/MM/YYYY HH:mm')} - ${until.format('DD/MM/YYYY HH:mm')}`;

      const itemProps = getItemProps(
        reservation.from,
        reservation.until,
        (String(reservation.user_id) === String(currentUserId) || isAdmin)
      );
      let startDate = moment(reservation.from);
      let endDate = moment(reservation.until);

      const isSelectedReservation = reservation.id
        && (editReservationModal?.reservation?.id === reservation.id);

      if (isSelectedReservation) {
        startDate = moment(editReservationModal?.reservation?.from);
        endDate = moment(editReservationModal?.reservation?.until);
      }

      const showPopover = !(newReservationModal?.show || editReservationModal?.show);

      return getTimelineItem({
        reservation, title, startDate, endDate, itemProps, showPopover, probe, from, until,
      });
    }));
  }, [editReservationModal, newReservationModal, reservations, currentUserId, users]);

  const horizontalLineClassNamesForGroup = (group: any) => (group.original.status !== 'online' ? ['highlight'] : '');

  // useEffect(() => {
  //   console.log(`items ${JSON.stringify(items)}`);
  // }, [items]);

  return (
    <>
      <TimelineWithTable
        horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
        canChangeGroup={false}
        TableProps={{
          columns,
          onSelectedRowsChange: handleSelectedRowsChange,
          selectable: false,
          skipReset: true,
        }}
        groups={groups}
        items={items}
        NewButtonProps={
          {
            id: 'new-reservation',
            content: 'New Reservation',
            onClick: () => setNewReservationModal(
              { show: true, from: undefined, until: undefined },
            ),
          }
        }
        onCanvasDoubleClick={handleCanvasDoubleClick}
        onItemMove={handleItemMove}
        onItemResize={handleItemResize}
        moveResizeValidator={moveResizeValidator}
        useResizeHandle
      />
      {newReservationModal?.show && (
        <CreateReservationModal
          onHide={() => setNewReservationModal({
            ...newReservationModal,
            show: false,
          })}
          onSuccess={handleNewReservationSuccess}
          probes={probes}
          reservations={reservations}
          selectedProbes={selectedProbes}
          {...newReservationModal}
        />
      )}
      {editReservationModal?.show && (
        <EditReservationModal
          onHide={() => setEditReservationModal({
            reservation: undefined,
            show: false,
          })}
          onSuccess={handleEditReservationSuccess}
          probe={_.find(probes, ['probe_name', editReservationModal?.reservation?.probe_name])}
          selectedReservation={editReservationModal?.reservation}
          show={editReservationModal?.show}
        />
      )}
    </>
  );
};

export default ReservationsTimeline;
