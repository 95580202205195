import React, { useEffect, useState } from 'react';
import { Col, Row, ProgressBar } from 'react-bootstrap';

import moment from 'moment';
import useConfirm from 'hooks/useConfirm';
import ResourcesModal from './ResourcesModal';

import useDeleteQueuedStatus from '../../hooks/services/scheduler/useDeleteQueuedStatus';
import useRemoveQueuedStatus from '../../hooks/services/scheduler/useRemoveQueuedStatus';
import useKillStatus from '../../hooks/services/scheduler/useKillStatus';

import { isSelected } from '../../MiniTessa/helpers';

const stfUrl = 'http://stf.its:9298/multi_view/';
const rpUrl = 'https://reporting.its-telekom.eu';

interface IOverviewProps {
  scheduledJobs: any,
  getScheduledJobs: any,
  statuses: any,
  getStatuses: any,
  queuedStatuses: any,
  getQueuedStatuses: any,
  users: any,
  isAdmin: any,
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
}

const RunningJobs = ({
  scheduledJobs,
  getScheduledJobs,
  statuses,
  getStatuses,
  queuedStatuses,
  getQueuedStatuses,
  users,
  isAdmin,
  setMessage,
  setErrMessage
}: IOverviewProps) => {
  const [showResourcesModal, setShowResourcesModal] = useState(false);
  const [jobUuid, setJobUuid] = useState<string>('');
  const [showAll, setShowAll] = useState<string[]>([]);

  const scheduledJobsKeyArray = Object.keys(scheduledJobs);

  const sortedKeys = Object.keys(scheduledJobs).sort((keyA, keyB) => {
    const jobA = scheduledJobs[keyA][1]; // Access the job object inside the array
    const jobB = scheduledJobs[keyB][1];

    // Check if the jobs have the "last_run" property
    const hasLastRunA = 'last_run' in jobA;
    const hasLastRunB = 'last_run' in jobB;

    // Compare based on the presence of the "last_run" property
    if (!hasLastRunA && hasLastRunB) return -1; // jobA doesn't have "last_run" but jobB does
    if (hasLastRunA && !hasLastRunB) return 1; // jobB doesn't have "last_run" but jobA does

    // Compare based on the "last_run" property (if available)
    if (hasLastRunA && hasLastRunB) {
      const lastRunA = new Date(jobA.last_run);
      const lastRunB = new Date(jobB.last_run);

      if (lastRunA > lastRunB) return -1; // jobA has later "last_run" than jobB
      if (lastRunA < lastRunB) return 1; // jobB has later "last_run" than jobA
    }

    // If both have the same "last_run" status or no "last_run" property, compare based on keys
    return keyA.localeCompare(keyB);
  });

  const scheduledJobsKeyArrayFiltered = scheduledJobsKeyArray
    .filter((item:any) => (
      (queuedStatuses.jobs.some((obj:any) => obj.arguments[0].schedule_id === item))
      || (
        statuses.statuses.length > 0
        && statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status === 'working'))
      )
    ));

  const getQueuedScheduleId = () => queuedStatuses.jobs.map(
    (item:any) => item.arguments[0].schedule_id
  );

  const getStatusesScheduleId = () => statuses.statuses.map(
    (item:any) => item.options.schedule_id
  );

  const mergedScheduleIdArray = [...getQueuedScheduleId(), ...getStatusesScheduleId()];
  const uniqueScheduleIdArray = Array.from(new Set(mergedScheduleIdArray));

  const uniqueScheduleIdArrayFiltered = (uniqueScheduleIdArray
    .filter((item:any) => ((
      queuedStatuses.jobs.some((obj:any) => obj.arguments[0].schedule_id === item))
        || (
          statuses.statuses.length > 0
          && statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status === 'working')))
    )));

  const notScheduledJobsKeyArrayFiltered = (uniqueScheduleIdArrayFiltered
    .filter((item) => (!scheduledJobsKeyArrayFiltered.includes(item))))
    .filter((item) => item !== null && item !== undefined)
    .filter((item:any) => ((
      queuedStatuses.jobs.some((obj:any) => (obj.arguments[0].schedule_id === item)
      && obj.arguments[0]?.schedule_name))
        || (
          statuses.statuses.length > 0
          && statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status === 'working')))
    ));

  const getFirst = (sortedKeys
    .filter((item:any) => (!scheduledJobs[item][1].paused
          && ((queuedStatuses.jobs.some((obj:any) => obj.arguments[0].schedule_id === item))
        || (
          statuses.statuses.length > 0
          && statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status === 'working')))
          ))));

  const getPaused = (scheduledJobsKeyArray
    .filter((item:any) => (scheduledJobs[item][1].paused)
        && ((queuedStatuses.jobs.some((obj:any) => obj.arguments[0].schedule_id === item))
            || (
              statuses.statuses.length > 0
              && statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status === 'working')))
        )));

  const orderedScheduleIdArray = [...getFirst,
    ...notScheduledJobsKeyArrayFiltered,
    ...getPaused];

  const regex = /(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/;

  const parseFinishedOn = (dateString: any) => {
    const match = regex.exec(dateString);
    if (match) {
      const date = new Date();
      const dateUtc = new Date(match[0]);
      const offset = date.getTimezoneOffset();
      const localDate = new Date(dateUtc.getTime() - offset * 60000);
      return moment(localDate).utc(true).format('YYYY-MM-DD, HH:mm:ss');
    }
    return 'no match';
  };

  const confirm = useConfirm();

  const [deleteQueuedStatus] = useDeleteQueuedStatus(
    {
      onSuccess: () => {
        setMessage('Queued Job Aborted');
        getScheduledJobs();
        getQueuedStatuses();
      },
      onError: () => setErrMessage('Failed to abort queued job'),
    },
  );

  const handleDeleteQueuedStatus = (id: any) => {
    confirm({
      body: 'Do you want to abort job?',
      onOk: () => deleteQueuedStatus({ id }),
      title: 'Abort job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const [removeQueuedStatus] = useRemoveQueuedStatus(
    {
      onSuccess: () => {
        setMessage('Job retried');
        getScheduledJobs();
      },
      onError: () => setErrMessage('Failed to retry job'),
    },
  );

  const handleRemoveQueuedStatus = (id: any) => {
    confirm({
      body: 'Do you want to retry queued job?',
      onOk: () => removeQueuedStatus({ id }),
      title: 'Retry queued job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const [killStatus] = useKillStatus(
    {
      onSuccess: () => {
        setMessage('Job aborted');
        getScheduledJobs();
      },
      onError: () => setErrMessage('Failed to abort job'),
    },
  );

  const handleKillStatus = (id: any) => {
    confirm({
      body: 'Do you want to abort job?',
      onOk: () => killStatus({ id }),
      title: 'Abort job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const handleShowAll = (scheduleId: string) => {
    if (isSelected(showAll, scheduleId)) {
      setShowAll(showAll?.filter((val: string) => val !== scheduleId));
    } else {
      setShowAll([...showAll || [], scheduleId]);
    }
  };

  const progress = (status: number, total: number) => {
    if ((status / total) > 0.1) {
      return Math.floor((status / total) * 100);
    } return 10;
  };

  const getStatusVariant = (status: string) => {
    let variant = '';
    if (status === 'completed') {
      variant = 'success';
    } else if (status === 'working') {
      variant = 'info';
    } else {
      variant = 'danger';
    }

    return variant;
  };

  useEffect(() => {
    getScheduledJobs();
    getQueuedStatuses();
    getStatuses();
  }, []);

  return (
    <>
      {orderedScheduleIdArray.length < 1 && <span>No running campaigns</span>}
      {orderedScheduleIdArray.map((key: any) => (
        <>
          <Row className="test-case-details">
            <div className="job-label">
              {(queuedStatuses.jobs.find((job:any) => job.arguments[0].schedule_id === key)
                ?.arguments[0].schedule_name)
              || (statuses.statuses.find((job:any) => job.options?.schedule_id === key)
                ?.options?.schedule_name)
              || (scheduledJobs[key] && scheduledJobs[key][1].name)
              || 'Campaign not active'}
            </div>
            <div className="test-case-content">
              {queuedStatuses.jobs.filter((job:any) => key === job.arguments[0].schedule_id)
                .slice(0, showAll.includes(key) ? 100 : 2)
                .map((job: any) => (
                  <Row className="border rounded p-3">
                    <Col>
                      <Row>
                        <strong>Details:</strong>
                      </Row>
                      <Row>
                        Approval ID:   {job.approval_id}
                      </Row>
                      <Row>
                        Created by:   {users.filter(
                        (item:any) => item.uid.toString() === job.arguments[0].user_id
                      )[0].username}
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <strong>Show:</strong>
                      </Row>
                      <Row>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => {
                            setShowResourcesModal(true);
                            setJobUuid(job.approval_id);
                          }}
                        >
                          Resources
                        </button>
                        {isAdmin && (
                        <a
                          href={`https://grafana01.its-telekom.eu/d/_p3ZeeUZk/appium-environment-logs?orgId=1&var-env_id=${(job?.uuid)}`}
                          target="_blank"
                          rel="noreferrer"
                        ><button type="button" className="btn btn-outline-primary btn-sm" disabled>Logs</button>
                        </a>
                        )}
                      </Row>
                      <Row>
                        <button type="button" className="btn btn-outline-primary btn-sm" disabled>Devices</button>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <strong>Job Execution Status:</strong>
                      </Row>
                      {job.jail_status && (
                        typeof job.jail_status.reason === 'string' ? (
                          <p>{job.jail_status.reason}</p>
                        ) : (
                          <>
                            {job.jail_status.reason.map((item: string[]) => (
                              <Row>{item[0]}</Row>
                            ))}
                          </>
                        )
                      )}
                      {!job.jail_status && <Row>Awaiting approval</Row>}
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          handleRemoveQueuedStatus(job.approval_id);
                        }}
                      >
                        Retry
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          handleDeleteQueuedStatus(job.approval_id);
                        }}
                      >
                        Abort
                      </button>
                    </Col>
                    <Col>
                      <div className="bu-py-1 bu-mb-1 text-right">
                        <button type="button" className="btn btn-outline-primary btn-sm" disabled>Results</button>
                      </div>
                    </Col>
                  </Row>
                ))}
            </div>

            <div className="test-case-content">
              {statuses.statuses.filter((status: any) => (key === status.options.schedule_id))
                .slice(0, showAll.includes(key) ? 100 : 2)
                .map((status: any) => (
                  <Row className="border rounded p-3">
                    <Col>
                      <Row>
                        <strong>Details:</strong>
                      </Row>
                      <Row>
                        Job ID:   {status.uuid}
                      </Row>
                      <Row>
                        Start:   {moment.unix(status.time).format('YYYY-MM-DD, H:mm:ss')}
                      </Row>
                      {status.status === 'completed' && (
                      <Row>
                        Finished on: {parseFinishedOn(status.message)}
                      </Row>
                      )}
                      {status.status === 'killed' && (
                      <Row>
                        Killed on: {parseFinishedOn(status.message)}
                      </Row>
                      )}
                      <Row>
                        Created by:   {users.filter(
                        (item: any) => item.uid.toString() === status.options.user_id
                      )[0].username}
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <strong>Show:</strong>
                      </Row>
                      <Row>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => {
                            setShowResourcesModal(true);
                            setJobUuid(status.uuid);
                          }}
                        >
                          Resources
                        </button>
                        {isAdmin && (
                        <a
                          href={`https://grafana01.its-telekom.eu/d/_p3ZeeUZk/appium-environment-logs?orgId=1&var-env_id=${(status?.uuid)}`}
                          target="_blank"
                          rel="noreferrer"
                        ><button type="button" className="btn btn-outline-primary btn-sm">Logs</button>
                        </a>
                        )}
                      </Row>
                      <Row>
                        <a
                          href={`${stfUrl}${(status?.options.probes.reduce((p:any, c:any) => `${p}%7C${c.identifier}`, ''))}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            disabled={status.status !== 'working'}
                          >
                            Devices
                          </button>
                        </a>
                      </Row>
                    </Col>
                    <Col>
                      <strong>Job Execution Status: {(status.status)
                        .charAt(0).toUpperCase() + (status.status).slice(1)}
                      </strong>
                      <ProgressBar
                        now={status.status !== 'completed' ? progress(status.num, status.total) : 100}
                        label={`${status.status !== 'completed' ? progress(status.num, status.total) : 100} %`}
                        style={{ margin: '0.4rem 0', background: '#ededed' }}
                        variant={getStatusVariant(status.status)}
                        className="square border"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        disabled
                      >
                        Retry
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        disabled={status.status === 'completed'}
                        onClick={() => {
                          handleKillStatus(status.uuid);
                        }}
                      >
                        Abort
                      </button>
                    </Col>
                    <Col>
                      <div className="bu-py-1 bu-mb-1 text-right">
                        <a
                          href={`${rpUrl}`}
                          target="_blank"
                          rel="noreferrer"
                        ><button type="button" className="btn btn-outline-primary btn-sm">Results</button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                ))}
            </div>
            {(((queuedStatuses.jobs.filter((job:any) => key === job.arguments[0].schedule_id))
              .length > 2)
              || (statuses.statuses.filter((status: any) => (key === status.options.schedule_id))
                .length > 2))
            && (
            <button
              type="button"
              className="btn btn-outline-primary btn-sm ml-auto at-show-all-button"
              onClick={() => handleShowAll(key)}
            >
              {showAll.includes(key) ? 'Show Less' : `Show All (${(queuedStatuses.jobs.filter((job:any) => key === job.arguments[0].schedule_id)
                .length)
                + (statuses.statuses.filter((status: any) => (key === status.options.schedule_id))
                  .length)})`}
            </button>
            )}
          </Row>
        </>
      ))}
      {showResourcesModal
                && (
                  <ResourcesModal
                    onHide={() => setShowResourcesModal(false)}
                    show={showResourcesModal}
                    queuedStatuses={queuedStatuses}
                    statuses={statuses}
                    jobUuid={jobUuid}
                  />
                )}
    </>
  );
};

export default RunningJobs;
