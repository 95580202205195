import _ from 'lodash';
import { queryCache, useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteReservationRequestData = {
  reservationId: number;
};

export default function useDeleteReservation(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ reservationId }: DeleteReservationRequestData) => axios.delete(
    `/reservations/api/reservation/${reservationId}`,
  ).then(({ data }) => data), { ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }

      queryCache.invalidateQueries('reservations');
    }
  });
}
