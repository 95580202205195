import React, { useState, useEffect } from 'react';

import {
  Card, CardDeck, Col, Container, Row,
} from 'react-bootstrap';

import { AxiosError } from 'axios';
import moment from 'moment';
import useAlerts from 'hooks/useAlerts';

import Tabs from 'components/Tabs';
import useUser from 'hooks/useUser';
import { useApiPolling } from 'hooks/apiPolling/apiPollingContext';
import MyReservationsTable from './MyReservationsTable';
import ReservationsTimeline from './ReservationsTimeline';

const Reservation = () => {
  const [activeTab, setActiveTab] = useState('table');
  const [firstLoad, setFirstLoad] = useState(true);
  const { isAdmin, uidNumber: userId } = useUser();

  const {
    reservations: data,
    isLoadingReservations,
    probes,
    isLoadingProbes,
    sims,
    probesError,
    simsError,
    reservationsError,
  } = useApiPolling();

  const { showAlert } = useAlerts();

  const [reservations, setReservations] = useState<any[]>([]);

  useEffect(() => {
    if (!isLoadingProbes && !isLoadingReservations) setFirstLoad(false);
  }, [isLoadingProbes, isLoadingReservations]);

  useEffect(() => {
    setReservations(data);
  }, [data]);

  useEffect(() => {
    let er;
    if (probesError) er = probesError as AxiosError;
    if (simsError) er = simsError as AxiosError;
    if (er) {
      showAlert({
        id: 'new-message',
        content: `Loading data: ${er.message}`,
        variant: 'danger',
      });
    }
  }, [probesError, simsError]);

  // handle reservationsError properly, backend send 404 where no matching reservation was found
  // even when service (endpoint) itself is working
  useEffect(() => {
    const er = reservationsError as AxiosError;
    if (er) {
      if (er.message !== 'No matching reservation found' || (er.message === 'No matching reservation found' && firstLoad)) {
        showAlert({
          id: 'new-message',
          content: `Loading reservations: ${er.message}`,
          variant: 'danger',
        });
      }
      if (er && (er.message === 'No matching reservation found')) {
        setReservations([]);
      }
    }
  }, [reservationsError]);

  return (
    <div className="pt-4">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="display-4">Reservations</h1>
          </Col>
        </Row>
        <>
          <CardDeck className="bu-mb-5">
            <Card>
              <Card.Body>
                <Card.Title>Active reservations</Card.Title>
                <Card.Text>
                  {/* show users active reservations */}
                  <span className="display-4">{reservations?.length >= 0 ? reservations.filter((reservation: any) => (String(reservation.user_id) === String(userId) || isAdmin) && moment(reservation.from).isBefore(moment()) && moment(reservation.until).isAfter(moment())).length : '-'}</span>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Planned reservations</Card.Title>
                <Card.Text>
                  {/* show users planned reservations */}
                  <span className="display-4">{reservations?.length >= 0 ? reservations.filter((reservation: any) => (String(reservation.user_id) === String(userId) || isAdmin) && moment(reservation.from).isAfter(moment())).length : '-'}</span>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Probes</Card.Title>
                <Card.Text>
                  <span className="display-4">{probes?.length ?? '-'}</span>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>SIMs</Card.Title>
                <Card.Text>
                  <span className="display-4">{sims?.filter((sim: any) => sim.position)?.length ?? '-'}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardDeck>
          <Tabs
            activeTab={activeTab}
            block
            onChange={(value) => setActiveTab(value)}
            tabs={[{
              content: 'My reservations',
              value: 'table',
            },
            {
              content: 'Timeline',
              value: 'timeline',
            }]}
          />
          <div className="border border-top-0 rounded-bottom bu-mb-5 bu-px-3 bu-py-2">
            {activeTab === 'timeline' && <ReservationsTimeline probes={probes} reservations={reservations} />}
            {activeTab === 'table' && (
              <MyReservationsTable
                probes={probes}
                reservations={reservations
                  ? reservations
                    .sort((a: any, b: any) => ((a.from < b.from) ? 1 : -1))
                  : []}
              />
            )}
          </div>
        </>
      </Container>
    </div>
  );
};

export default Reservation;
