import React, { useEffect, useState } from 'react';
import { Col, Row, ProgressBar } from 'react-bootstrap';

import moment from 'moment';
import useConfirm from 'hooks/useConfirm';
import ResourcesModal from './ResourcesModal';

import useKillStatus from '../../hooks/services/scheduler/useKillStatus';

import { isSelected } from '../../MiniTessa/helpers';

interface IOverviewProps {
  scheduledJobs: any,
  getScheduledJobs: any,
  statuses: any,
  getStatuses: any,
  queuedStatuses: any,
  getQueuedStatuses: any,
  users: any,
  isAdmin:any,
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
}

const RunningJobs = ({
  scheduledJobs,
  getScheduledJobs,
  statuses,
  getStatuses,
  queuedStatuses,
  getQueuedStatuses,
  users,
  isAdmin,
  setMessage,
  setErrMessage
}: IOverviewProps) => {
  const [showResourcesModal, setShowResourcesModal] = useState(false);
  const [jobUuid, setJobUuid] = useState<string>('');
  const [showAll, setShowAll] = useState<string[]>([]);

  const scheduledJobsKeyArray = Object.keys(scheduledJobs);

  const scheduledJobsKeyArrayFiltered = scheduledJobsKeyArray
    .filter((item:any) => (
      (statuses.statuses.length > 0
      && statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status !== 'working')))
      && !(queuedStatuses.jobs.some((obj:any) => obj.arguments[0].schedule_id === item))
    ));

  const getStatusesScheduleId = () => statuses.statuses.map(
    (item:any) => item.options.schedule_id
  );

  const uniqueScheduleIdArray = Array.from(new Set(getStatusesScheduleId()));

  const notScheduledJobsKeyArrayFiltered = (uniqueScheduleIdArray
    .filter((item:any) => !scheduledJobsKeyArrayFiltered.includes(item)))
    .filter((item) => item !== null && item !== undefined)
    .filter((item:any) => (
      statuses.statuses.some((obj:any) => (obj.options.schedule_id === item && obj.status !== 'working'))
    ));

  const orderedScheduleIdArray = [...scheduledJobsKeyArrayFiltered,
    ...notScheduledJobsKeyArrayFiltered];

  const regex = /(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/;

  const parseFinishedOn = (dateString: any) => {
    const match = regex.exec(dateString);
    if (match) {
      const date = new Date();
      const dateUtc = new Date(match[0]);
      const offset = date.getTimezoneOffset();
      const localDate = new Date(dateUtc.getTime() - offset * 60000);
      return moment(localDate).utc(true).format('YYYY-MM-DD, HH:mm:ss');
    }
    return 'no match';
  };

  const confirm = useConfirm();

  const [killStatus] = useKillStatus(
    {
      onSuccess: () => {
        setMessage('Job aborted');
        getScheduledJobs();
      },
      onError: () => setErrMessage('Failed to abort job'),
    },
  );

  const handleKillStatus = (id: any) => {
    confirm({
      body: 'Do you want to abort job?',
      onOk: () => killStatus({ id }),
      title: 'Abort job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const handleShowAll = (scheduleId: string) => {
    if (isSelected(showAll, scheduleId)) {
      setShowAll(showAll?.filter((val: string) => val !== scheduleId));
    } else {
      setShowAll([...showAll || [], scheduleId]);
    }
  };

  const progress = (status: number, total: number) => {
    if ((status / total) > 0.1) {
      return Math.floor((status / total) * 100);
    } return 10;
  };

  const getStatusVariant = (status: string) => {
    let variant = '';
    if (status === 'completed') {
      variant = 'success';
    } else if (status === 'working') {
      variant = 'info';
    } else {
      variant = 'danger';
    }

    return variant;
  };

  useEffect(() => {
    getScheduledJobs();
    getQueuedStatuses();
    getStatuses();
  }, []);

  return (
    <>
      {orderedScheduleIdArray.length < 1 && <span>No finished campaigns</span>}
      {orderedScheduleIdArray.map((key: any) => (
        <>
          <Row className="test-case-details">
            <div className="job-label">
              {(queuedStatuses.jobs.find((job:any) => job.arguments[0].schedule_id === key)
                ?.arguments[0].schedule_name)
              || (statuses.statuses.find((job:any) => job.options?.schedule_id === key)
                ?.options?.schedule_name)
              || (scheduledJobs[key] && scheduledJobs[key][1].name)
              || 'Campaign not active'}
            </div>
            <div className="test-case-content">
              {(statuses.statuses.filter((status: any) => (key === status.options.schedule_id)))
                .slice(0, showAll.includes(key) ? 100 : 2)
                .map((status: any) => (
                  <Row className="border rounded p-3">
                    <Col>
                      <Row>
                        <strong>Details:</strong>
                      </Row>
                      <Row>
                        Job ID:   {status.uuid}
                      </Row>
                      <Row>
                        Started on:   {moment.unix(status.time).format('YYYY-MM-DD, H:mm:ss')}
                      </Row>
                      {status.status === 'completed' && (
                      <Row>
                        Finished on: {parseFinishedOn(status.message)}
                      </Row>
                      )}
                      {status.status === 'killed' && (
                      <Row>
                        Killed on: {parseFinishedOn(status.message)}
                      </Row>
                      )}
                      <Row>
                        Created by:   {users.filter(
                        (item: any) => item.uid.toString() === status.options.user_id
                      )[0].username}
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <strong>Show:</strong>
                      </Row>
                      <Row>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => {
                            setShowResourcesModal(true);
                            setJobUuid(status.uuid);
                          }}
                        >
                          Resources
                        </button>
                        {isAdmin && (
                        <a
                          href={`https://grafana01.its-telekom.eu/d/_p3ZeeUZk/appium-environment-logs?orgId=1&var-env_id=${(status?.uuid)}`}
                          target="_blank"
                          rel="noreferrer"
                        ><button type="button" className="btn btn-outline-primary btn-sm">Logs</button>
                        </a>
                        )}
                      </Row>
                    </Col>
                    <Col>
                      <strong>Job Execution Status: {(status.status)
                        .charAt(0).toUpperCase() + (status.status).slice(1)}
                      </strong>
                      <ProgressBar
                        now={status.status !== 'completed' ? progress(status.num, status.total) : 100}
                        label={`${status.status !== 'completed' ? progress(status.num, status.total) : 100} %`}
                        style={{ margin: '0.4rem 0', background: '#ededed' }}
                        variant={getStatusVariant(status.status)}
                        className="square border"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        disabled
                      >
                        Retry
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        disabled={status.status === 'completed' || status.status === 'failed' || status.status === 'killed'}
                        onClick={() => {
                          handleKillStatus(status.uuid);
                        }}
                      >
                        Abort
                      </button>
                    </Col>
                    <Col>
                      <div className="bu-py-1 bu-mb-1 text-right">
                        <a
                          href="https://reporting.its-telekom.eu"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            disabled={status.status === 'failed' || status.status === 'killed'}
                          >
                            Results
                          </button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                ))}
            </div>
            {(statuses.statuses.filter((status: any) => (key === status.options.schedule_id))
              .length > 2)
            && (
            <button
              type="button"
              className="btn btn-outline-primary btn-sm ml-auto at-show-all-button"
              onClick={() => handleShowAll(key)}
            >
              {showAll.includes(key) ? 'Show Less' : `Show All (${statuses.statuses.filter((status: any) => (key === status.options.schedule_id))
                .length})`}
            </button>
            )}
          </Row>
        </>
      ))}
      {showResourcesModal
                && (
                  <ResourcesModal
                    onHide={() => setShowResourcesModal(false)}
                    show={showResourcesModal}
                    queuedStatuses={queuedStatuses}
                    statuses={statuses}
                    jobUuid={jobUuid}
                  />
                )}
    </>
  );
};

export default RunningJobs;
