/* eslint-disable react/jsx-props-no-spreading */
// Page on this route will be accessible only for logged in users
import _ from 'lodash';

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import useUser from 'hooks/useUser';

import {
  Col, Container, Row,
} from 'react-bootstrap';
import Login from 'pages/Login';

const InitializingSSO = () => (
  <Container as="main">
    <Row>
      <Col md={{ span: 6, offset: 3 }} xs={{ span: 12, offset: 0 }}>
        <div className="bu-mt-5">
          <h1 className="font-weight-bold h4 text-center">TESSA</h1>
        </div>
        <div className="bu-mb-4 bu-mt-4"><h2 className="display-4 text-center">Initializing SSO ...</h2></div>
      </Col>
      <Col />
    </Row>
  </Container>
);

const PrivateRoute = ({ roles, users, ...routeProps }: any) => {
  const { keycloak, initialized } = useKeycloak();

  const { roles: currentUserRoles, preferred_username: currentUser } = useUser();

  useEffect(() => {
    if ((initialized && !keycloak?.authenticated)) {
      // if not iframe do a login redirect
      if (window.top === window.self) {
        keycloak.login();
      }
    }
  }, [initialized, keycloak]);

  if (window.top !== window.self) {
    if ((initialized && !keycloak?.authenticated)) {
      return <Login />;
    }
  }

  if (!initialized) return <InitializingSSO />;

  if (!keycloak?.authenticated) return null;

  const isRoleRestricted = !_.isEmpty(roles);
  const userHasRole = !_.chain(roles).intersection(currentUserRoles).isEmpty().value();
  const isUserRestricted = !_.isEmpty(users);
  const isAllowedUser = _.includes(users, currentUser);

  // disable acces if route is role restricted and user doesn't have role
  // and if rute is user restricted and user is not allowed
  if ((isRoleRestricted && !userHasRole) || (isUserRestricted && !isAllowedUser)) {
    return null;
  }

  return (
    <Route {...routeProps} />
  );
};

export default PrivateRoute;
